import getConfig from 'config/config';

const SPACE_ID = getConfig('contentful.spaceId');

type ImageUrlParams = {
  assetId: string;
  imageName: string;
  imageToken: string;
  spaceId?: string;
};

const buildStaticImageUrl = ({
  assetId,
  imageName,
  imageToken,
  spaceId = SPACE_ID,
}: ImageUrlParams) => {
  return `https://images.ctfassets.net/${spaceId}/${assetId}/${imageToken}/${imageName}`;
};

export const comingSoonImageUrl = buildStaticImageUrl({
  assetId: '2dssvIa7AlXaFzY755TwMY',
  imageName: 'coming-soon.png',
  imageToken: 'fa1e3f98fc0d0c2fbf81b1b5d6fb2253',
});
