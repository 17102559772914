import classnames from 'classnames';
import { memo } from 'react';

import textFormatter from 'lib/textFormatter';
import { checkIfHasDiscountWithNumbers } from 'lib/utils/price';

import styles from './PriceInfo.module.scss';

type PriceInfoProps = {
  compareAtPrice?: number | null;
  compareAtPriceClassName?: string;
  currencyCode?: string; // TODO: CH[950] Force selection of currencyCode.
  price: number;
  priceClassName?: string;
};

const PriceInfo = ({
  compareAtPrice = 0,
  compareAtPriceClassName,
  currencyCode = 'USD', // TODO: CH[950] Force selection of currencyCode.
  price,
  priceClassName,
}: PriceInfoProps) => {
  if (!price) {
    return null;
  }

  const hasDiscount = checkIfHasDiscountWithNumbers(price, compareAtPrice);

  return (
    <div className={styles.price}>
      <span
        className={classnames(
          { [styles.isDiscount]: hasDiscount },
          priceClassName
        )}
      >
        {textFormatter.formatCurrency(price, currencyCode)}
      </span>
      {compareAtPrice && hasDiscount && (
        <span
          className={classnames(styles.compareAtPrice, compareAtPriceClassName)}
        >
          {textFormatter.formatCurrency(compareAtPrice, currencyCode)}
        </span>
      )}
    </div>
  );
};

export default memo(PriceInfo);
