import first from 'lodash/first';
import uniqBy from 'lodash/uniqBy';

import { RecommendedProductVariant } from 'components/Recommendations/RecommendedProducts/RecommendedProducts';

import Logger from 'lib/utils/Logger';

import { AssetFkaImage } from 'types/app';
import {
  CartLineItemsInfoQuery,
  Image,
  ImageDisplayUrlFragment,
} from 'types/generated/api';

type VariantForImageSelector = {
  galleryImages?: Array<ImageDisplayUrlFragment | null | undefined>;
  modelImages?: Array<ImageDisplayUrlFragment | null | undefined>;
  productImages?: Array<ImageDisplayUrlFragment | null | undefined>;
};

type ImageOption = 'galleryImages' | 'modelImages' | 'productImages';

type VariantForCartLineItemImage = {
  galleryImages: Image[];
  productImages: Image[];
};

// By default, tries selecting the first model image and falls back to the first productImage.
// If the preferProductImage flag is set to true then the order is reversed.
// If none exist, falls back to { url: '' }.
export const getVariantImage = (
  variant: VariantForImageSelector | RecommendedProductVariant,
  preferProductImage = false,
  preferGalleryImage = false
): NonNullable<ImagesFromCartLineItemsInfoQuery>[number] | null | undefined => {
  const images = getVariantImages(
    variant,
    preferProductImage,
    preferGalleryImage
  );

  if (!images?.[0]) {
    return { url: '' };
  }

  return images[0];
};

type ImagesFromCartLineItemsInfoQuery = NonNullable<
  NonNullable<CartLineItemsInfoQuery['productVariant'][number]>[
    | 'galleryImages'
    | 'productImages']
>;

// By default tries to get all modelImages, falls back to productImages.
// If the preferProductImage flag is set to true then the reverse happens.
// If neither exist, it defaults to an array with one image with a blank url.
export const getVariantImages = (
  variant: VariantForImageSelector | RecommendedProductVariant,
  preferProductImage = false,
  preferGalleryImage = false
): ImagesFromCartLineItemsInfoQuery => {
  let primaryImages: ImageOption;
  let secondaryImages: ImageOption;

  if (preferProductImage) {
    primaryImages = 'productImages';
    secondaryImages = 'galleryImages';
  } else if (preferGalleryImage) {
    primaryImages = 'galleryImages';
    secondaryImages = 'modelImages';
  } else {
    primaryImages = 'modelImages';
    secondaryImages = 'galleryImages';
  }

  let images = variant?.[primaryImages] ?? [];
  if (images.length === 0) {
    images = variant?.[secondaryImages] ?? [];
  }

  if (images.length === 0) {
    images.push({ url: '' });
  }

  return images;
};

// Find image to use in the Cart page for each line item
export const getVariantImageForCartLineItem = (
  variant: VariantForCartLineItemImage
) => {
  try {
    const { galleryImages, productImages } = variant;
    return first(productImages) || first(galleryImages);
  } catch (error) {
    Logger.error(
      'Could not fetch variant items for item carriage issues',
      error
    );
    return null;
  }
};

// Failsafe to ensure there are no duplicate images
// Flattens data, removes duplicates via set, and recreates objects.
export const removeDuplicateAssets = (
  assets: AssetFkaImage[]
): AssetFkaImage[] => uniqBy(assets, 'url');
