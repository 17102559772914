import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import CarouselPreloader from 'assets/icons/ic-carousel-preloader.inline.svg';
import LeftArrowIcon from 'assets/icons/ic-chevrone-left.inline.svg';
import RightArrowIcon from 'assets/icons/ic-chevrone-right.inline.svg';

import styles from './Carousel.module.scss';
const RESIZE_EVENT = 'resize';
const RESIZE_EVENT_DELAY = 200; // in miliseconds
const IMG_LOAD_EVENT = 'load';
const ITEM_COUNT_BEFORE_FETCH = 4; // items displayed before preload more products on swipe mobile version.
const DEFAULT_ARROW_HEIGHT = '100%'; // by default, the arrow container height is 100% of its parent.
const SCROLL_EVENT_DELAY = 100;
const isElementImage = (elem: Element): elem is HTMLImageElement => {
  return elem.nodeName === 'IMG';
};
type CarouselProps = {
  cardsContainerClassName?: string;
  children: ReactElement | ReactElement[];
  className?: string;
  // The arrows will be vertically centered relative to the height of this element.
  classNameForArrowContainerMarginTop?: string;
  classNameForCenteringArrows?: string; // The arrows will be vertically offset by the margin-top of this element.
  classNameLeftArrow?: string;
  classNameRightArrow?: string;
  // the number of cards that should be scrolled when the user clicks the arrow.
  itemsTotalLimit?: number;
  lazyLoading?: boolean;
  leftArrowCallback?: () => void;
  loading?: boolean;
  numberOfCardsToScroll?: number;
  rightArrowCallback?: () => void;
};
type CarouselState = {
  amountToScroll: number;
  arrowContainerHeight: number | string;
  arrowContainerMarginTop: number;
  hideLeftArrow: boolean;
  hideRightArrow: boolean;
  overflowAmount: number;
};
const Carousel = ({
  cardsContainerClassName,
  children,
  className,
  classNameForArrowContainerMarginTop,
  classNameForCenteringArrows,
  classNameLeftArrow,
  classNameRightArrow,
  itemsTotalLimit,
  lazyLoading = false,
  leftArrowCallback,
  loading = false,
  numberOfCardsToScroll = 1,
  rightArrowCallback,
}: CarouselProps) => {
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const [carouselState, setCarouselState] = useState<CarouselState>({
    amountToScroll: 0,
    arrowContainerHeight: DEFAULT_ARROW_HEIGHT,
    arrowContainerMarginTop: 0,
    hideLeftArrow: true,
    hideRightArrow: true,
    overflowAmount: 0,
  });

  const remainingScrollablePixelsOnLeft = useCallback(() => {
    const node = cardsContainerRef.current;
    if (!node) {
      return 0;
    }
    return node.scrollLeft;
  }, []);
  const remainingScrollablePixelsOnRight = useCallback(
    (overflowAmount?: number) => {
      const node = cardsContainerRef.current;
      if (!node) {
        return 0;
      }
      const pixelsScrolled = node.scrollLeft;
      return (overflowAmount || carouselState.overflowAmount) - pixelsScrolled;
    },
    [carouselState.overflowAmount]
  );

  const calculateItemMargin = useCallback(() => {
    const node = cardsContainerRef.current;
    if (!node || node.childElementCount === 0) {
      return 0;
    }

    const firstCard = node.firstChild as Element;
    const style = window.getComputedStyle(firstCard);
    const margin =
      parseFloat(style.marginRight || '') + parseFloat(style.marginLeft || '');

    return margin;
  }, []);

  const calculateItemWidth = useCallback(() => {
    const node = cardsContainerRef.current;
    if (!node || node.childElementCount === 0) {
      return 0;
    }

    const firstCard = node.firstChild as Element;

    return firstCard.clientWidth;
  }, []);

  /**
   * Calculates how many pixels should be scrolled when the user clicks an arrow.
   * Uses the width + margin of the first element in the carousel to determine the amount.
   * If the first element has not yet been rendered then it uses the entire width of the carousel.
   */
  const calculateAmountToScroll = useCallback(() => {
    const width = calculateItemWidth();
    const margin = calculateItemMargin();

    return (width + margin) * numberOfCardsToScroll;
  }, [calculateItemMargin, calculateItemWidth, numberOfCardsToScroll]);

  const calculateState = useCallback(() => {
    const node = cardsContainerRef.current;
    if (!node || node.childElementCount === 0) {
      return;
    }

    const widthExcludingOverflow = node.clientWidth;
    const widthIncludingOverflow = node.scrollWidth;
    const overflowAmount = widthIncludingOverflow - widthExcludingOverflow;
    const amountToScroll = calculateAmountToScroll();
    const itemMargin = calculateItemMargin();
    const shouldRenderArrows = overflowAmount > itemMargin;
    const hideLazyLoadingScroll =
      !lazyLoading ||
      (itemsTotalLimit !== undefined &&
        node.childElementCount >= itemsTotalLimit);
    const hideRightArrow =
      hideLazyLoadingScroll &&
      (!shouldRenderArrows ||
        remainingScrollablePixelsOnRight(overflowAmount) <= itemMargin);
    const hideLeftArrow =
      !shouldRenderArrows || remainingScrollablePixelsOnLeft() <= 0;

    setCarouselState(state => ({
      ...state,
      amountToScroll,
      hideLeftArrow,
      hideRightArrow,
      overflowAmount,
    }));
  }, [
    calculateAmountToScroll,
    calculateItemMargin,
    itemsTotalLimit,
    lazyLoading,
    remainingScrollablePixelsOnLeft,
    remainingScrollablePixelsOnRight,
  ]);
  const scroll = (amountToScroll: number) => {
    const node = cardsContainerRef.current;
    if (!node) {
      return;
    }
    node.scrollBy({
      behavior: 'smooth',
      left: amountToScroll,
    });
  };
  const scrollLeft = async () => {
    const { amountToScroll } = carouselState;
    if (loading) {
      return null;
    }
    if (leftArrowCallback) {
      leftArrowCallback();
    }
    scroll(amountToScroll * -1);
  };
  const scrollRight = async () => {
    const { amountToScroll } = carouselState;
    if (loading) {
      return null;
    }
    if (rightArrowCallback) {
      await rightArrowCallback();
    }
    scroll(amountToScroll);
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: async () => {
      const node = cardsContainerRef.current;
      if (!node) {
        return;
      }
      const firstCard = node.firstChild as Element;
      const itemWidth = firstCard.clientWidth;
      if (loading) {
        return null;
      }
      if (itemsTotalLimit && node.childElementCount >= itemsTotalLimit) {
        return null;
      }
      if (
        rightArrowCallback &&
        remainingScrollablePixelsOnRight() < itemWidth * ITEM_COUNT_BEFORE_FETCH
      ) {
        rightArrowCallback();
      }
    },
  });
  useEffect(() => {
    const node = cardsContainerRef.current;
    if (!node) {
      return;
    }
    /**
     * Fired after an the image element of a card has loaded.
     * It sets the height of the arrows equal the height of the image.
     */
    const handleCardImageLoad = (event: Event) => {
      const imageElement = event.currentTarget as HTMLImageElement;
      const newArrowContainerHeight = imageElement?.clientHeight;
      setCarouselState(state => ({
        ...state,
        arrowContainerHeight: newArrowContainerHeight,
      }));
      imageElement?.removeEventListener(IMG_LOAD_EVENT, handleCardImageLoad);
    };

    /**
     * Calculates the margin-top offset of the arrow container.
     * This value is used for centering the arrows in the slider.
     * If props.classNameForCenteringArrows is provided then the arrows
     * will be centered relative to the height of that element.
     * Otherwise, the default arrow height is returned.
     */
    const calculateArrowContainerHeight = () => {
      if (classNameForCenteringArrows) {
        const elem = node.querySelector(`.${classNameForCenteringArrows}`);
        if (elem) {
          /**
           * Checks if the element is an <img> element and has not yet loaded.
           * If so, it attaches an event listener to set state.arrowContainerHeight once the image loads.
           */
          if (isElementImage(elem)) {
            elem.addEventListener(IMG_LOAD_EVENT, handleCardImageLoad);
          }
          return elem.clientHeight;
        }
      }
      return DEFAULT_ARROW_HEIGHT;
    };

    /**
     * Calculates the margin-top of the arrow container.
     * This value is used for aligning the arrows in the slider.
     * If props.classNameForArrowContainerMarginTop is provided then the arrows
     * will be offset by the margin-top of that element.
     * Otherwise, 0 is returned.
     * @returns the margin-top of the element with the classNameForCenteringArrows class name or 0.
     */
    const calculateArrowContainerMarginTop = () => {
      if (classNameForCenteringArrows) {
        const elem = node.querySelector(
          `.${classNameForArrowContainerMarginTop}`
        );
        if (elem) {
          const style = window.getComputedStyle(elem);
          return parseInt(style.getPropertyValue('margin-top'), 10);
        }
      }
      return 0;
    };

    const arrowContainerHeight = calculateArrowContainerHeight();
    const arrowContainerMarginTop = calculateArrowContainerMarginTop();
    setCarouselState(state => ({
      ...state,
      arrowContainerHeight,
      arrowContainerMarginTop,
    }));
    calculateState();
  }, [
    calculateState,
    carouselState.hideRightArrow,
    classNameForCenteringArrows,
    classNameForArrowContainerMarginTop,
    itemsTotalLimit,
    numberOfCardsToScroll,
  ]);

  useEffect(() => {
    const node = cardsContainerRef.current;
    const handleResize = debounce(calculateState, RESIZE_EVENT_DELAY, {
      trailing: true,
    });
    window.addEventListener(RESIZE_EVENT, handleResize);
    const handleScroll = debounce(calculateState, SCROLL_EVENT_DELAY);
    if (node) {
      node.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener(RESIZE_EVENT, handleResize);
      if (node) {
        node.removeEventListener('scroll', handleScroll);
      }
    };
  }, [calculateState]);

  useEffect(() => {
    calculateState();
  }, [calculateState, children]);

  return (
    <div {...swipeHandlers} className={classnames(styles.root, className)}>
      <div
        aria-label="carousel left arrow"
        className={classnames(classNameLeftArrow, styles.arrowContainerLeft, {
          [styles.hideArrow]: carouselState.hideLeftArrow,
        })}
        onClick={scrollLeft}
        onKeyPress={keyPressHandler({ Enter: scrollLeft })}
        role="button"
        style={{
          height: carouselState.arrowContainerHeight,
          marginTop: carouselState.arrowContainerMarginTop,
        }}
        tabIndex={0}
      >
        {loading ? (
          <div className={styles.arrowWrapperLoading}>
            <CarouselPreloader className={styles.icon} />
          </div>
        ) : (
          <div className={styles.arrowWrapper}>
            <LeftArrowIcon className={styles.icon} />
          </div>
        )}
      </div>
      <div
        className={classnames(styles.cardsContainer, cardsContainerClassName)}
        ref={cardsContainerRef}
      >
        {children}
      </div>
      <div
        aria-label="carousel right arrow"
        className={classnames(classNameRightArrow, styles.arrowContainerRight, {
          [styles.hideArrow]: !loading && carouselState.hideRightArrow,
        })}
        onClick={scrollRight}
        onKeyPress={keyPressHandler({ Enter: scrollRight })}
        role="button"
        style={{
          height: carouselState.arrowContainerHeight,
          marginTop: carouselState.arrowContainerMarginTop,
        }}
        tabIndex={0}
      >
        {loading ? (
          <div className={styles.arrowWrapperLoading}>
            <CarouselPreloader className={styles.icon} />
          </div>
        ) : (
          <div className={styles.arrowWrapper}>
            <RightArrowIcon className={styles.icon} />
          </div>
        )}
      </div>
      {loading && (
        <div className={styles.mobilePreloadContainer}>
          <div className={styles.arrowWrapperLoading}>
            <CarouselPreloader className={styles.icon} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Carousel;
