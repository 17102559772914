import classnames from 'classnames';
import { ReactElement } from 'react';

import styles from './ErrorPageBody.module.scss';

type ErrorPageBodyProps = {
  className?: string;
};

const ErrorPageBody = ({ className }: ErrorPageBodyProps): ReactElement => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.heading}>It&apos;s not you, it&apos;s us.</div>
    <div className={styles.subHeading}>
      We&apos;re sorry, something went wrong on our end.
    </div>
    <div className={styles.supportText}>Our experts are available 24/7.</div>
    <div className={styles.supportLinks}>
      <strong>support@verishop.com</strong>
    </div>
  </div>
);

export default ErrorPageBody;
